import React,{useState,useEffect,useRef} from 'react'
import SidebarLarge from '../Components/SidebarLarge';
import Navbar from '../Components/Navbar';
import {motion} from 'framer-motion'
import { documentInformation, lawTypes } from '../Data/LaywerTypes';
import { createDraft } from '../API/DraftCreateCall';
import { BiCopy, BiEdit, BiHome, BiSave } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import toast,{ Toaster } from 'react-hot-toast';
import { driver } from 'driver.js';
import "driver.js/dist/driver.css";
import { IoInformationCircleOutline } from 'react-icons/io5';
import ReactMarkdown from "react-markdown";
export default function DraftCreator() {
  const navigate=useNavigate()
  const [lawType,setLawType]=useState(null)
  const [documentType,setDocumentType]=useState(null)
  const [loading,setLoading]=useState(false)
  const [formData,setFormData]=useState({})
  const [draft,setDraft]=useState(null)
  const [editDraft,setEditDraft]=useState(false)
  const showDriver=()=>{
    const driverObj=driver({
      showProgress:true,
      steps:[
        { element: '#law_types', popover: { title: 'Choose Law Type', description: 'You choose the law type that you want to search about'}}
      ]
    })
    driverObj.drive()
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleDraftChange = (e) => {
    setDraft({
      ...draft,
      "content": e.target.value,
    });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    // Handle form submission logic
    setLoading(true)
    const res = await createDraft(formData,lawType,documentType,"gpt-4o")
    setDraft(res)
    setLoading(false)
    console.log('Form submitted:', formData);
  };
  return (
    <div className='w-screen min-h-screen p-8 md:pl-64 pt-20 '>
        <Toaster
          position="top-right"
          reverseOrder={false}
        />
        <SidebarLarge active="case-studies"/>
        <Navbar/>
        {lawType==null && <div className='w-full relative'>
          <button onClick={showDriver} className='text-2xl absolute top-0 right-0 text-gray-500 hover:text-gray-700'><IoInformationCircleOutline/></button>
          <h3 className='font-bold text-xl md:text-3xl'>Please choose the <span className='text-primary-900'>law type</span></h3>
          <h3 className='text-gray-700 text-sm mb-6 '>This is the category of law that your desired draft falls into</h3>
          <div id="law_types" className='grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-4'>
            {Object.entries(lawTypes).map(([key, value])=>{
              return (
                <motion.button onClick={()=>{
                  console.log(lawTypes[key])
                  setLawType(key)
                }} whileTap={{scale:0.95}} whileHover={{translateY:2,rotateZ:"-2deg"}} className='px-4 py-8 border rounded shadow-md bg-gray-50 hover:border-primary-900'>
                  <h3 className='text-sm md:text-lg font-semibold '>{key}</h3>
                </motion.button>
              )
            })}
          </div>

        </div>}
        {lawType!=null && documentType==null && <div>
          <h3 className='font-bold text-xl md:text-3xl'>Please choose the <span className='text-primary-900'>document type</span></h3>
          <h3 className='text-gray-700 text-sm mb-6 '>Choose the type of document draft that you want to create</h3>
          <div id="document_types" className='grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-4'>
            {lawTypes[lawType].map((a,idx)=>{
              return (
                <motion.button onClick={()=>setDocumentType(a)} whileTap={{scale:0.95}} whileHover={{translateY:2,rotateZ:"-2deg"}} className='px-4 py-8 border rounded shadow-md bg-gray-50 hover:border-primary-900'>
                  <h3 className='text-sm md:text-lg font-semibold '>{a}</h3>
                </motion.button>
              )
            })}
          </div>
          <button onClick={()=>setLawType(null)} className='text-sm font-semibold text-primary-900 my-4 py-2 px-4 bg-primary-50 border border-primary-900 hover:text-primary-50 hover:bg-primary-900'>Prev</button>
        </div>}
        {loading && <div>
          <p>Loading next stage...</p>
        </div>}
        {documentType!=null && !loading && draft===null && <div>
          <h3 className='font-bold text-xl md:text-3xl'>Please enter the <span className='text-primary-900'>Values </span>(Optional)</h3>
          <h3 className='text-gray-700 text-sm mb-6 '>The more information you give, the better the draft document will be</h3>
            <form onSubmit={handleSubmit}>
            {Object.entries(documentInformation[documentType]).map(([key, value])=>{
              return (
                <div whileTap={{scale:0.95}} whileHover={{translateY:2,rotateZ:"-2deg"}} className='px-4 py-8 border rounded shadow-md bg-gray-50 my-2 '>
                  <h3 className='text-md md:text-lg text-primary-900 font-bold mb-4 md:mb-6'>{key}</h3>
                  {value.map(a=>(
                    <div className="my-2">
                      <label htmlFor="input" className="block text-gray-700 text-sm font-semibold mb-2">
                        {a}
                      </label>
                      <input
                        type="text"
                        id={a}
                        name={a}
                        value={formData[a]}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border text-xs border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                        placeholder={"Enter "+a}
                      />
                    </div>
                  ))}
                </div>
              )
            })}
            <button onClick={()=>setDocumentType(null)} className='text-sm mr-2 font-semibold text-primary-900 my-4 py-2 px-4 bg-primary-50 border border-primary-900 hover:text-primary-50 hover:bg-primary-900'>Prev</button>
            <button type="submit" className='text-sm font-semibold text-primary-900 my-4 py-2 px-4 bg-primary-50 border border-primary-900 hover:text-primary-50 hover:bg-primary-900'>Submit Details</button>
            </form>
            
        </div>}
        {draft!=null && 
        <div>
          <h3 className='font-bold text-xl md:text-3xl'>Generated <span className='text-primary-900'>Document </span></h3>
          <div className='w-full bg-primary-50 px-4 py-2 my-4'>
              <p className='text-sm'>~<span className='text-primary-900 font-bold text-xl'>{draft.cost}BDT</span> was the total cost of generation</p>  
            </div>
          <div className='p-4 bg-gray-50 rounded'>
            <div className='w-full flex justify-end space-x-2'>
              <button onClick={()=>{
                navigator.clipboard.writeText(draft.content)
                console.log(typeof draft.content)
                console.log(draft.content)
                toast('Copied to clipboard', {
                  icon: '📘',
                });
              }} className='text-lg md:text-xs flex items-center px-2 md:px-4 py-2 my-4 font-semibold text-primary-900 bg-primary-50 hover:bg-primary-900 hover:text-primary-50'><BiSave className='md:mr-2'/><span className='hidden md:block'>Save Document</span></button>
              <button onClick={()=>setEditDraft(!editDraft)} className='text-lg md:text-xs flex items-center px-2 md:px-4 py-2 my-4 font-semibold text-primary-900 bg-primary-50 hover:bg-primary-900 hover:text-primary-50'><BiEdit className='md:mr-2'/><span className='hidden md:block'>Edit Document</span></button>
              <button onClick={()=>{
                navigator.clipboard.writeText(draft.content)
                console.log(typeof draft.content)
                console.log(draft.content)
                toast('Copied to clipboard', {
                  icon: '📘',
                });
              }} className='text-lg md:text-xs flex items-center px-2 md:px-4 py-2 my-4 font-semibold text-primary-900 bg-primary-50 hover:bg-primary-900 hover:text-primary-50'><BiCopy className='md:mr-2'/><span className='hidden md:block'>Copy Document</span></button>
            </div>
            {!editDraft && <ReactMarkdown className='text-sm text-wrap overflow-hidden' style={{fontFamily:"Poppins"}}>{draft.content}</ReactMarkdown>}  
            {editDraft && <textarea className='text-sm text-wrap w-full min-h-[200px]' style={{fontFamily:"Poppins"}} onChange={handleDraftChange} value={draft.content}/>} 
          </div>
          <button onClick={()=>navigate("/draft")} className='text-sm border border-primary-900 flex items-center px-4 py-2 my-4 font-semibold text-primary-900 bg-primary-50 hover:bg-primary-900 hover:text-primary-50'><BiHome className='mr-2'/>Go Home</button>
        </div>}
        
    </div>
  )
}
