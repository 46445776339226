import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import SidebarLarge from "../Components/SidebarLarge";
import Navbar from "../Components/Navbar";
import { IoInformationCircleOutline } from "react-icons/io5";
import CaseFiles from "../Components/CaseOrganization/CaseFiles";
import CaseDates from "../Components/CaseOrganization/CaseDates";
import CaseSummary from "../Components/CaseOrganization/CaseSummary";
import { FaAngleLeft } from "react-icons/fa6";

export default function CaseElementPage() {
  const location=useLocation();
  const caseDetails=location.state?.caseElement;

  const [activeTab, setActiveTab] = useState("summary");
  const { id } = useParams();

  const renderTabContent = () => {
    switch (activeTab) {
      case "summary":
        return <CaseSummary caseId={id} />;
      case "files":
        return <CaseFiles caseId={id} />;
      case "dates":
        return <CaseDates caseId={id} />;
      default:
        return null;
    }
  };


  return (
    <div className="w-screen h-screen px-4 md:pl-64 overflow-y-hidden">
      <SidebarLarge active="case-organization" />
      {/* <Navbar /> */}

      {/* <div className="flex flex-col w-full mb-4 ">
        <div className="w-full relative">
          <button className="text-2xl absolute top-0 right-0 text-gray-500 hover:text-gray-700">
            <IoInformationCircleOutline />
          </button>
          <h3 className="font-bold text-xl md:text-3xl">
            Case Element <span className="text-purple-900">Details 📄</span>
          </h3>
          <h3 className="text-gray-700 text-sm mb-6 ">
            Manage the details of your case element
          </h3>
        </div>
      </div> */}
      <div className="p-4 flex items-center">
        <button onClick={()=>window.history.back()} className="p-2 border mr-4 text-purple-900 hover:text-white hover:bg-purple-900"><FaAngleLeft  /></button>
        <h2 className="text-purple-900 font-bold text-xl">{caseDetails.name}</h2>
      </div>
      <div >
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {["summary", "files", "dates",].map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`${
                  activeTab === tab
                    ? "border-purple-900 text-purple-800"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm mr-8`}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </nav>
        </div>
      </div>

      {renderTabContent()}
    </div>
  );
}
