import axios from "axios";
import { SERVER_URL } from "../Data/apiData";

const apiService = {
  get: async (endpoint) => {
    try {
      const response = await fetch(`${SERVER_URL}/${endpoint}`, {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error(`GET request failed: ${response.statusText}`);
      }

      return await response.json();
    } catch (error) {
      console.error(error);
      return null;
    }
  },

  post: async (endpoint, data) => {
    console.log(`${SERVER_URL}/${endpoint}`);
    try {
      const response = await fetch(`${SERVER_URL}/${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`POST request failed: ${response.statusText}`);
      }

      return await response.json();
    } catch (error) {
      console.error(error);
      return null;
    }
  },

  postForm: async (endpoint, form) => {
    try {
      const response = await axios.post(`${SERVER_URL}/${endpoint}`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        timeout: 300000, // 5 minutes
      });

      return response.data
    } catch (error) {
      console.error(error);
      return null;
    }
  },

  put: async (endpoint, data) => {
    try {
      const response = await fetch(`${SERVER_URL}/${endpoint}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`PUT request failed: ${response.statusText}`);
      }

      return await response.json();
    } catch (error) {
      console.error(error);
      return null;
    }
  },

  del: async (endpoint) => {
    try {
      const response = await fetch(`${SERVER_URL}/${endpoint}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error(`DELETE request failed: ${response.statusText}`);
      }

      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  },
};

export default apiService;
