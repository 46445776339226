import React, { useState } from "react";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { render } from "@testing-library/react";

// Import PDF Renderer Elements
import { Viewer, Worker } from "@react-pdf-viewer/core"; //npm install pdfjs-dist@3.4.120
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import CaseChatter from "./CaseChatter";

const renderJson = (data) => {
  return Object.entries(data).map(([key, value]) => {
    if (typeof value === "string") {
      return (
        <div key={key} className="mb-2">
          <span className="text-sm font-semibold text-primary-900">{key}:</span>
          <hr className="mb-2" />
          <p className="text-gray-700 text-sm ml-8">{value}</p>
        </div>
      );
    } else if (Array.isArray(value)) {
      return (
        <div key={key} className="mb-2">
          <span className="font-semibold text-sm text-primary-900">{key}:</span>
          <hr className="mb-2" />
          <ul className="list-disc list-inside ml-4">
            {value.map((item, index) => (
              <li key={index} className="text-gray-700 text-sm">
                {typeof item != "object" && item}
                {typeof item === "object" && renderJson(item)}
              </li>
            ))}
          </ul>
        </div>
      );
    } else if (typeof value === "object") {
      return (
        <div>
          <span className="text-sm font-semibold text-primary-900 ">
            {key}:
          </span>
          <hr className="mb-2" />
          <div className="ml-8">{renderJson(value)}</div>
        </div>
      );
    }
    return null;
  });
};

export default function PDFCardJSONWhole({ data, idx }) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [showDetail, setShowDetail] = useState(false);
  const [active, setActive] = useState("summary");
  const [showSummaryDetail, setShowSummaryDetail] = useState(false);
  const [activeKey, setActiveKey] = useState("");
  const content = JSON.parse(data.content);

  return (
    <div className="w-full px-4 ">
      <div className="w-full my-2 bg-white p-4 md:p-6 flex items-center justify-between">
        <div className="flex items-center">
          <a
            target="_blank"
            className="p-2 rounded border hover:bg-gray-200"
            href={data.pdf_link}
          >
            📒
          </a>
          <div className="flex flex-col ml-2">
            <p className="text-sm font-semibold">{content["Case Title"]}</p>
            <p className="text-xs text-gray-700">
              Contains the full case in this PDF
            </p>
            <p className="text-xs text-gray-700">
              [Click on the 📒 icon to download]
            </p>
          </div>
        </div>
        <div>
          {!showDetail && (
            <button
              className="hover:bg-gray-100 ml-2 p-1 md:p-2 rounded-full border"
              onClick={() => setShowDetail(true)}
            >
              <FaAngleDown />
            </button>
          )}
          {showDetail && (
            <button
              className="hover:bg-gray-100 ml-2 p-1 md:p-2 rounded-full border"
              onClick={() => setShowDetail(false)}
            >
              <FaAngleUp />
            </button>
          )}
        </div>
      </div>
      {showDetail && (
        <div className="p-4 w-full flex items-center justify-center bg-white rounded shadow mb-2">
          <div className="flex items-center justify-center space-x-2">
            <div className="grid grid-cols-3 gap-2">
              {active === "summary" && (
                <button className="h-full font-semibold text-xs md:text-sm mb-4 text-center border rounded border-primary-900 bg-primary-900 text-primary-50 px-2 md:px-4 py-2">
                  Summary
                </button>
              )}
              {active !== "summary" && (
                <button
                  onClick={() => setActive("summary")}
                  className="h-full font-semibold text-xs md:text-sm mb-4 text-center border rounded border-primary-900 bg-primary-50 text-primary-900 px-2 md:px-4 py-2"
                >
                  Summary
                </button>
              )}
              {active === "pdf" && (
                <button className="h-full font-semibold text-xs md:text-sm mb-4 text-center border rounded border-primary-900 bg-primary-900 text-primary-50 px-2 md:px-4 py-2">
                  Read PDF
                </button>
              )}
              {active !== "pdf" && (
                <button
                  onClick={() => setActive("pdf")}
                  className="h-full font-semibold text-xs md:text-sm mb-4 text-center border rounded border-primary-900 bg-primary-50 text-primary-900 px-2 md:px-4 py-2"
                >
                  Read PDF
                </button>
              )}
              {active === "question" && (
                <button className="h-full font-semibold text-xs md:text-sm mb-4 text-center border rounded border-primary-900 bg-primary-900 text-primary-50 px-2 md:px-4 py-2">
                  Ask Questions
                </button>
              )}
              {active !== "question" && (
                <button
                  onClick={() => setActive("question")}
                  className="h-full font-semibold text-xs md:text-sm mb-4 text-center border rounded border-primary-900 bg-primary-50 text-primary-900 px-2 md:px-4 py-2"
                >
                  Ask Questions
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      
      {showDetail && active === "summary" && !showSummaryDetail && (
        <div className="grid grid-cols-1 gap-2">
          <div className="p-4 w-full flex flex-col  bg-white rounded shadow">
            <p className="font-semibold text-md mb-4 w-full bg-primary-50 text-primary-900 px-4 py-2">
              Case Title
            </p>
            <div className="px-4 text-sm mb-2">{content["Case Title"]}</div>
            <p className="font-semibold text-md mb-4 w-full bg-primary-50 text-primary-900 px-4 py-2">
              Summary
            </p>
            <div className="px-4">{renderJson(content["Summary"])}</div>
            <button
              onClick={() => setShowSummaryDetail(true)}
              className="mx-auto text-xs font-semibold text-gray-200 px-4 py-2 my-4 bg-primary-500 hover:bg-primary-600"
            >
              More Details
            </button>
          </div>
        </div>
      )}

      {showDetail && active === "summary" && showSummaryDetail && (
        <div className="flex flex-col item-start justify-start gap-2  p-4 bg-white rounded shadow">
          <p className="font-semibold text-md mb-4 w-full bg-primary-50 text-primary-900 px-4 py-2">
            Case Title
          </p>
          <div className="px-4 text-sm mb-2">{content["Case Title"]}</div>
          <p className="font-semibold text-md mb-4 w-full bg-primary-50 text-primary-900 px-4 py-2">
            Summary
          </p>
          <div className="px-4">{renderJson(content["Summary"])}</div>

          {Object.entries(content).map(([key, value]) => {
            if (key == "Summary" || key == "Case Title") {
              return;
            }
            return (
              <div className="">
                <p
                  onClick={() => setActiveKey(key)}
                  className="font-semibold text-md mb-4 w-full bg-primary-50 text-primary-900 px-4 py-2"
                >
                  {key}
                </p>
                {typeof value == "string" && (
                  <p className="text-sm px-4">{value}</p>
                )}
                {typeof value == "object" && (
                  <div className="px-4">{renderJson(value)}</div>
                )}
              </div>
            );
          })}
          <button
            onClick={() => setShowSummaryDetail(false)}
            className="mx-auto text-xs font-semibold text-gray-200 px-4 py-2 my-4 bg-primary-500 hover:bg-primary-600"
          >
            Less Details
          </button>
        </div>
      )}
      {showDetail && active === "question" && <CaseChatter caseJson={content}/>}
      {showDetail && active === "pdf" && (
        <div className="w-full flex items-center justify-center bg-white rounded shadow p-4">
          <div className="w-full xl:w-1/2 max-h-[400px] md:max-h-[700px] overflow-y-scroll">
            {/** This pdfjs-dist@3.4.120 MUST MATCH THE VERSION */}
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer
                fileUrl={data.pdf_link}
                plugins={[defaultLayoutPluginInstance]}
              />
            </Worker>
          </div>
        </div>
      )}
    </div>
  );
}
