import React, { useState, useEffect, useRef } from "react";
import SidebarLarge from "../Components/SidebarLarge";
import Navbar from "../Components/Navbar";
import { motion } from "framer-motion";
import ReactMarkdown from "react-markdown";

import { IoIosArrowRoundUp } from "react-icons/io";
import axios from "axios";
import { SERVER_URL } from "../Data/apiData";
import PDFCardJSONWhole from "../Components/CaseStudies/PDFCardJSONWhole";
import SidebarBeta from "../Components/SidebarBeta";
import { HiInformationCircle } from "react-icons/hi";
import {
  IoInformationCircle,
  IoInformationCircleOutline,
} from "react-icons/io5";
import InfoModal from "../Components/CaseStudies/InfoModal";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { Oval } from 'react-loader-spinner';
import { createQusetion } from "../API/analytics";
export default function Chatter() {
    const textareaRef = useRef(null);
    const [loading,setLoading]=useState(false)
    const [findingMore,setFindingMore]=useState(false)
    const [data,setData]=useState(null)
    const [cases,setCases]=useState([])
    const [input,setInput]=useState("")
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showDriver=()=>{
      const driverObj=driver({
        showProgress:true,
        steps:[
          { element: '#input_box', popover: { title: 'Input Field', description: 'You can search your case scenarios in your language here.'}},
          { element: '#search_icon', popover: { title: 'Search Button', description: 'Once done with writing, click this button to find your relevant cases'}},
          { element: '#output_box', popover: { title: 'Output Box', description: 'This is where your output will be shown and the relevant cases will be found'}},
        ]
      })
      driverObj.drive()
    }
    const openModal = () => {
        setIsModalOpen(true);
      };
    
      const closeModal = () => {
        setIsModalOpen(false);
      };
    const findRelevantMarkdown=async()=>{
        setInput("")
        setLoading(true)
        console.log("HELP")
        const res=await axios.post(SERVER_URL+"/case_query/get_similar",{
            "query":input
        })
        console.log(res.data)
        setData(res.data)
        setLoading(false)
    }
    const findMoreJSON=async()=>{
      setInput("")
      setFindingMore(true)
      //console.log("HELP")
      const res=await axios.post(`${SERVER_URL}/case_query/get_similar_json?limit=5&offset=${data.length}`,{
          "query":input
      })
      console.log(res.data)
      res.data.map(a=>{
          setData(data=>[...data,a])
      })
     
      setFindingMore(false)
  }
    const findRelevantJSON=async()=>{
        setInput("")
        setLoading(true)
        //console.log("HELP")
        const mid= await createQusetion({question:input,type:"chat"})
        const res=await axios.post(SERVER_URL+"/case_query/chat",{
            "query":input
        })
        console.log(res.data)
        setData(res.data.response)
        setCases(res.data.results)
        setLoading(false)
    }
    useEffect(() => {
        const textarea = textareaRef.current;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }, [input]);
  return (
    <div className='w-screen min-h-screen p-8 md:pl-64 pt-20 '>
        <SidebarLarge active="chatter"/>
        <Navbar/>
        {/** MAIN BODY */}
        <div className='flex flex-col w-full mb-4 '>
             <div className='w-full relative'>
                <button onClick={showDriver} className='text-2xl absolute top-0 right-0 text-gray-500 hover:text-gray-700'><IoInformationCircleOutline/></button>
                <h3 className='font-bold text-xl md:text-3xl'>Agent <span className='text-primary-900'>Chatter 💬 </span></h3>
                <h3 className='text-gray-700 text-sm mb-6 '>Enter a case detail and find out what the outcome might be!</h3>
             </div>
             <div className='w-full flex' id="input_box">
              <textarea
                  ref={textareaRef}
                  value={input}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault(); // Prevent default Enter behavior
                      findRelevantJSON();
                    }
                  }}
                  onChange={(e) => setInput(e.target.value)}
                  placeholder='✨ Tell me about the case you want to find'
                  className='w-full text-sm overflow-hidden resize-none border-2 focus:border-purple-900 border-gray-300 focus:outline-purple-900 outline-none p-2'
                  rows={1}
                  style={{outline: 'none'}}
                />
                <motion.button id="search_icon" onClick={findRelevantJSON} className='p-2 ml-1 hover:text-white hover:bg-purple-900 bg-gray-100'>
                    <IoIosArrowRoundUp size={24} />
                </motion.button>
             </div>
             
          
        </div>
        <div id="output_box" className='relative overflow-auto h-[72vh] rounded-xl bg-gray-100'>
          {data!=null && !loading && <div className='p-4'>
         
            {/* <div className='w-full flex flex-col p-4 pb-2'>
              <span className='p-2'>📌</span><p className='text-md text-wrap'>Here are some of the relevant cases that I could find based on your case description.</p>    
            </div>
            <div className='grid grid-cols-2 md:grid-cols-3 gap-4'>
            {cases.map((a,idx)=>(<PDFCardJSONWhole data={a.payload} idx={idx}/>))}
            </div> */}
            <ReactMarkdown className='text-sm text-wrap overflow-hidden' style={{fontFamily:"Poppins"}}>{data}</ReactMarkdown>
          </div>}
          {loading && <p className='p-4 w-full h-full flex items-center justify-center'><Oval visible={true} height="20" width="29" color="#581c87" ariaLabel="oval-loading"/>Generating Scenario outcome...</p>}
      </div>
    </div>
  );
}
