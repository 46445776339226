import React, { useState, useEffect } from "react";
import { FiPlus, FiEdit2, FiTrash2, FiX } from "react-icons/fi";
import { ClipLoader } from "react-spinners";
import toast, { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import apiService from "../apiService";
//@ts-checkimport { set } from "react-datepicker/dist/date_utils";

export default function CaseDates() {
  const location = useLocation();
  const { caseElement } = location.state || {};

  const [dates, setDates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [newDateName, setNewDateName] = useState("");
  const [newDate, setNewDate] = useState(new Date());
  const [editingDate, setEditingDate] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    fetchDates();
  }, []);

  const fetchDates = async () => {
    setIsLoading(true);
    try {
      // Simulated API call
      // Await new Promise((resolve) => setTimeout(resolve, 1000));
      const response = await apiService.get(
        `case-dates/case-element/${caseElement.id}`
      );
      if(response){
        setDates(response);
      }
    } catch (error) {
      toast.error("Failed to fetch dates");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddDate = async (e) => {
    e.preventDefault();
    setIsAdding(true);
    try {
      // Simulated API call
      //   await new Promise((resolve) => setTimeout(resolve, 1000));
      const payload = {
        case_element_id: caseElement.id,
        name: newDateName,
        date: newDate.toISOString(),
      };

      const response = await apiService.post("case-dates", payload);

      console.log(response);
      setDates([...dates, response]);
      setShowAddModal(false);
      setNewDateName("");
      setNewDate(new Date());
      toast.success("Date added successfully");
    } catch (error) {
      toast.error("Failed to add date");
    } finally {
      setIsAdding(false);
    }
  };

  const handleEditDate = async (e) => {
    e.preventDefault();
    setIsEditing(true);
    try {
      console.log(editingDate);
      // Simulated API call
      // Await new Promise((resolve) => setTimeout(resolve, 1000));
      
      let payload = {
        name: editingDate.name,
        date: editingDate.date
      }      

      const response = await apiService.put(`case-dates/${editingDate.id}`,payload)

      const updatedDates = dates.map((date) =>
        date.id === editingDate.id
          ? { ...editingDate, date: editingDate.date.toISOString() }
          : date
      );
      setDates(updatedDates);
      setShowEditModal(false);
      setEditingDate(null);
      toast.success("Date updated successfully");
    } catch (error) {
      toast.error("Failed to update date");
    } finally {
      setIsEditing(false);
    }
  };

  const handleDeleteDate = async (id) => {
    setIsDeleting(true);
    try {
      // Simulated API call
      const response = await apiService.del(`case-dates/${id}`)
      const updatedDates = dates.filter((date) => date.id !== id);
      setDates(updatedDates);
      toast.success("Date deleted successfully");
    } catch (error) {
      toast.error("Failed to delete date");
    } finally {
      setIsDeleting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <ClipLoader color="#4A90E2" size={50} />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <Toaster />
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-purple-900">Case Dates</h2>
        <button
          onClick={() => setShowAddModal(true)}
          className="bg-purple-900 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded flex items-center"
        >
          <FiPlus className="mr-2" />
          Add Date
        </button>
      </div>

      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <table className="min-w-full table-auto">
          <thead>
            <tr className="bg-gray-100 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-left">Event Name</th>
              <th className="py-3 px-6 text-left">Date & Time</th>
              <th className="py-3 px-6 text-left">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm">
            {dates.map((date) => (
              <tr
                key={date.id}
                className="border-b border-gray-200 hover:bg-gray-50"
              >
                <td className="py-3 px-6 text-left">{date.name}</td>
                <td className="py-3 px-6 text-left">
                  {new Date(date.date).toLocaleString()}
                </td>
                <td className="py-3 px-6 text-left">
                  <div className="flex items-center">
                    <button
                      onClick={() => {
                        setEditingDate({ ...date, date: new Date(date.date) });
                        setShowEditModal(true);
                      }}
                      className="text-purple-900 hover:text-purple-800 mr-4"
                      disabled={isEditing || isDeleting}
                    >
                      <FiEdit2 />
                    </button>
                    <button
                      onClick={() => handleDeleteDate(date.id)}
                      className="text-red-500 hover:text-red-700"
                      disabled={isEditing || isDeleting}
                    >
                      <FiTrash2 />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showAddModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
          <div className="bg-white p-5 rounded-lg shadow-xl w-96">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-bold text-black">Add New Date</h3>
              <button
                onClick={() => setShowAddModal(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <FiX size={24} />
              </button>
            </div>
            <form onSubmit={handleAddDate}>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="eventName"
                >
                  Event Name
                </label>
                <input
                  type="text"
                  id="eventName"
                  value={newDateName}
                  onChange={(e) => setNewDateName(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="eventDate"
                >
                  Date & Time
                </label>
                <DatePicker
                  selected={newDate}
                  onChange={(date) => setNewDate(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="flex items-center justify-end">
                <button
                  type="submit"
                  className="bg-purple-900 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  disabled={isAdding}
                >
                  {isAdding ? <ClipLoader color="#ffffff" size={20} /> : "Add"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showEditModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
          <div className="bg-white p-5 rounded-lg shadow-xl w-96">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-bold text-black">Edit Date</h3>
              <button
                onClick={() => setShowEditModal(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <FiX size={24} />
              </button>
            </div>
            <form onSubmit={handleEditDate}>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="editEventName"
                >
                  Event Name
                </label>
                <input
                  type="text"
                  id="editEventName"
                  value={editingDate?.name || ""}
                  onChange={(e) =>
                    setEditingDate({ ...editingDate, name: e.target.value })
                  }
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="editEventDate"
                >
                  Date & Time
                </label>
                <DatePicker
                  selected={editingDate?.date}
                  onChange={(date) => setEditingDate({ ...editingDate, date })}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="flex items-center justify-end">
                <button
                  type="submit"
                  className="bg-purple-900 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  disabled={isEditing}
                >
                  {isEditing ? (
                    <ClipLoader color="#ffffff" size={20} />
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
