import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import SidebarLarge from "../Components/SidebarLarge";
import Navbar from "../Components/Navbar";
import { IoInformationCircleOutline } from "react-icons/io5";
import { FiPlus, FiEdit2 } from "react-icons/fi";
import apiService from "../Components/apiService";
import {motion} from 'framer-motion'
export default function CaseOrganization() {
  const [caseElements, setCaseElements] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [newCaseName, setNewCaseName] = useState("");
  const [editingCase, setEditingCase] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const userId = "9eb43bc4-e485-413e-b453-b4ae0019e88f";
  const navigate = useNavigate();

  useEffect(() => {
    fetchCaseElements();
  }, []);

  const fetchCaseElements = async () => {
    setIsFetching(true);
    try {
      // Simulated API call
      const response = await apiService.get(`case-elements/user/${userId}`);
      if (response) {
        setCaseElements(response);
      }
    } catch (error) {
      toast.error("Failed to fetch case elements. Please try again.");
    } finally {
      setIsFetching(false);
    }
  };

  const handleCreateCaseElement = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Simulated API call
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const payload = {
        name: newCaseName,
        user_id: userId,
      };

      const response = await apiService.post("case-elements", payload);
      // console.log(response)

      setCaseElements([...caseElements, response], response);
      setShowCreateModal(false);
      setNewCaseName("");
      toast.success("Case element created successfully!");
    } catch (error) {
      toast.error("Failed to create case element. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditCaseElement = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Simulated API call
      let payload = {
        name: editingCase.name,
        status: editingCase.status,
      };

      const response = await apiService.put(
        `case-elements/${editingCase.id}`,
        payload
      );

      const updatedCaseElements = caseElements.map((caseElement) =>
        caseElement.id === editingCase.id ? editingCase : caseElement
      );

      setCaseElements(updatedCaseElements);
      setShowEditModal(false);
      setEditingCase(null);
      toast.success("Case element updated successfully!");
    } catch (error) {
      toast.error("Failed to update case element. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "active":
        return "bg-green-100 text-green-800";
      case "closed":
        return "bg-red-100 text-red-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  return (
    <div className="w-screen min-h-screen p-8 md:pl-64 pt-20">
      <Toaster position="top-right" />
      <SidebarLarge active="case-organization" />
      <Navbar />

      <div className="flex flex-col w-full mb-4">
        <div className="w-full relative">
          <button className="text-2xl absolute top-0 right-0 text-gray-500 hover:text-gray-700">
            <IoInformationCircleOutline />
          </button>
          <h3 className="font-bold text-xl md:text-3xl">
            Case <span className="text-purple-900">Organization 📄</span>
          </h3>
          <h3 className="text-gray-700 text-sm mb-6">
            Organize all the details related to your cases in one place
          </h3>
        </div>
      </div>

      <div className="mb-4 flex justify-between items-center">
        <h2 className="text-2xl font-bold">My Case Folders</h2>
        <button
          onClick={() => setShowCreateModal(true)}
          className="bg-purple-900 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded flex items-center"
        >
          <FiPlus className="mr-2" />
          Add Case Folder
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {isFetching ? (
          <div className="col-span-full p-4 text-center">
            Loading case elements...
          </div>
        ) : (
          
          caseElements.map((caseElement) => (
            <motion.div
              whileHover={{y:-5}}

              key={caseElement.id}
              className="bg-white border shadow-md rounded-lg overflow-hidden hover:shadow-lg transition-shadow duration-300 relative hover:border-purple-900"
            >
              <div
                className="p-4 cursor-pointer"
                onClick={() =>
                  navigate(`/case-element/${caseElement.id}`, {
                    state: { caseElement },
                  })
                }
              >
                <span
                  className={`px-4 py-1 mb-2 rounded text-xs ${getStatusColor(
                    caseElement.status
                  )}`}
                >
                  {caseElement.status}
                </span>
                <h3 className="font-semibold text-lg mt-2">
                  {caseElement.name}
                </h3>
                
              </div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setEditingCase(caseElement);
                  setShowEditModal(true);
                }}
                className="absolute top-4 right-4 text-purple-900 border p-2 rounded border-purple-900 hover:bg-purple-900 hover:text-white"
              >
                <FiEdit2 size={18} />
              </button>
            </motion.div>
          ))
        )}
      </div>

      {showCreateModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
          <div className="bg-white p-5 rounded-lg shadow-xl w-96">
            <h3 className="text-lg font-bold text-black mb-4">
              Create New Case Element
            </h3>
            <form onSubmit={handleCreateCaseElement}>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="caseName"
                >
                  Case Name
                </label>
                <input
                  type="text"
                  id="caseName"
                  value={newCaseName}
                  onChange={(e) => setNewCaseName(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="flex items-center justify-end">
                <button
                  type="button"
                  onClick={() => setShowCreateModal(false)}
                  className="text-gray-500 hover:text-gray-700 mr-4"
                  disabled={isLoading}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-purple-900 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  disabled={isLoading}
                >
                  {isLoading ? "Creating..." : "Create"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showEditModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
          <div className="bg-white p-5 rounded-lg shadow-xl w-96">
            <h3 className="text-lg font-bold text-black mb-4">
              Edit Case Element
            </h3>
            <form onSubmit={handleEditCaseElement}>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="editCaseName"
                >
                  Case Name
                </label>
                <input
                  type="text"
                  id="editCaseName"
                  value={editingCase?.name || ""}
                  onChange={(e) =>
                    setEditingCase({ ...editingCase, name: e.target.value })
                  }
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="editCaseStatus"
                >
                  Status
                </label>
                <select
                  id="editCaseStatus"
                  value={editingCase?.status || ""}
                  onChange={(e) =>
                    setEditingCase({ ...editingCase, status: e.target.value })
                  }
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                >
                  <option value="Active">Active</option>
                  <option value="Closed">Closed</option>
                </select>
              </div>
              <div className="flex items-center justify-end">
                <button
                  type="button"
                  onClick={() => setShowEditModal(false)}
                  className="text-gray-500 hover:text-gray-700 mr-4"
                  disabled={isLoading}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-purple-900 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  disabled={isLoading}
                >
                  {isLoading ? "Updating..." : "Update"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
