import React, { useState, useEffect } from "react";
import { FiPlus, FiEdit2, FiTrash2, FiX } from "react-icons/fi";
import { ClipLoader } from "react-spinners";
import toast, { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import apiService from "../apiService";

export default function CaseFiles() {
  const location = useLocation();
  const { caseElement } = location.state || {};
  console.log(caseElement);

  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [newFileName, setNewFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [editingFile, setEditingFile] = useState(null);
  const [editFileName, setEditFileName] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    setIsLoading(true);
    try {
      const response = await apiService.get(
        `case-files/case-element/${caseElement.id}`
      );
      if (response) {
        setFiles(response);
      }
      setIsLoading(false);
    } catch {
      toast.error("Failed to fetch files");
      setIsLoading(false);
    }
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    if (!selectedFile || !newFileName) return;

    setIsUploading(true);

    try {
      const formData = new FormData();
      formData.append("file_name", newFileName);
      formData.append("file", selectedFile);
      formData.append("case_element_id", caseElement.id);

      console.log(JSON.stringify(formData))

      const response = await apiService.postForm("case-files", formData);
      console.log(response)

      setFiles([...files, response]);
      setIsUploading(false);
      setShowUploadModal(false);
      setNewFileName("");
      setSelectedFile(null);
      toast.success("File uploaded successfully");
    } catch (error) {
      toast.error("Failed to upload file");
      setIsUploading(false);
    }
  };

  const handleEditFileName = async (e) => {
    e.preventDefault();
    setIsEditing(true);
    try {
      // Simulating API call
      console.log(editFileName, editingFile);

      let payload = {
        file_name: editFileName,
      };
      const response = await apiService.put(
        `case-files/${editingFile.id}`,
        payload
      );

      const updatedFiles = files.map((file) =>
        file.id === editingFile.id ? { ...file, file_name: editFileName } : file
      );

      setFiles(updatedFiles);
      setIsEditing(false);
      setShowEditModal(false);
      setEditingFile(null);
      setEditFileName("");
      toast.success("File name updated successfully");
    } catch (error) {
      toast.error("Failed to update file name");
      setIsEditing(false);
    }
  };

  const handleDeleteFile = async (id) => {
    setIsDeleting(true);
    try {
      const response = await apiService.del(`case-files/${id}`)
      const updatedFiles = files.filter((file) => file.id !== id);
      setFiles(updatedFiles);
      setIsDeleting(false);
      toast.success("File deleted successfully");
    } catch (error) {
      toast.error("Failed to delete file");
      setIsDeleting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <ClipLoader color="#4A90E2" size={50} />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-purple-900">Case Files</h2>
        <button
          onClick={() => setShowUploadModal(true)}
          className="bg-purple-900 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded flex items-center"
        >
          <FiPlus className="mr-2" />
          Add File
        </button>
      </div>
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <table className="min-w-full table-auto">
          <thead>
            <tr className="bg-gray-100 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-left">File Name</th>
              <th className="py-3 px-6 text-left">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm">
            {files.map((file) => (
              <tr
                key={file.id}
                className="border-b border-gray-200 hover:bg-gray-50"
              >
                <td className="py-3 px-6 text-left">
                  <a
                    href={file.file_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-black hover:underline"
                  >
                    {file.file_name}
                  </a>
                </td>
                <td className="py-3 px-6 text-left">
                  <div className="flex items-center">
                    <button
                      onClick={() => {
                        setEditingFile(file);
                        setNewFileName(file.file_name);
                        setShowEditModal(true);
                      }}
                      className="text-blue-500 hover:text-blue-700 mr-4"
                      disabled={isEditing || isDeleting}
                    >
                      <FiEdit2 />
                    </button>
                    <button
                      onClick={() => handleDeleteFile(file.id)}
                      className="text-red-500 hover:text-red-700"
                      disabled={isEditing || isDeleting}
                    >
                      <FiTrash2 />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showUploadModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
          <div className="bg-white p-5 rounded-lg shadow-xl w-96">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-bold text-black">Upload New File</h3>
              <button
                onClick={() => setShowUploadModal(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <FiX size={24} />
              </button>
            </div>
            <form onSubmit={handleFileUpload}>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="file"
                >
                  Choose File
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => {
                    setSelectedFile(e.target.files[0]);
                    setNewFileName(e.target.files[0].name);
                  }}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="fileName"
                >
                  File Name
                </label>
                <input
                  type="text"
                  id="fileName"
                  value={newFileName}
                  onChange={(e) => setNewFileName(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="flex items-center justify-end">
                <button
                  type="submit"
                  className={`bg-purple-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                    isUploading
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-purple-800"
                  }`}
                  disabled={isUploading}
                >
                  {isUploading ? (
                    <ClipLoader color="#ffffff" size={20} />
                  ) : (
                    "Upload"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showEditModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
          <div className="bg-white p-5 rounded-lg shadow-xl w-96">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-bold text-black">Edit File Name</h3>
              <button
                onClick={() => setShowEditModal(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <FiX size={24} />
              </button>
            </div>
            <form onSubmit={handleEditFileName}>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="editFileName"
                >
                  File Name
                </label>
                <input
                  type="text"
                  id="editFileName"
                  value={editFileName}
                  onChange={(e) => setEditFileName(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="flex items-center justify-end">
                <button
                  type="button"
                  onClick={() => setShowEditModal(false)}
                  className="text-gray-500 hover:text-gray-700 mr-4"
                  disabled={isEditing}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-purple-900 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  disabled={isEditing}
                >
                  {isEditing ? (
                    <ClipLoader color="#ffffff" size={20} />
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
