import OpenAI from 'openai/index.mjs'

export const createDraft=async(data,lawType,documentType,modelName)=>{
    const client=new OpenAI({apiKey:process.env.REACT_APP_OPENAI_API_KEY,dangerouslyAllowBrowser: true})
    let prompt=`\n\nNow a client has asked you to create him a draft ${documentType}. And has given you the following information : \n\n`
    Object.entries(data).map(([key,value],idx)=>{
        prompt=prompt+`${idx+1}. ${key} : ${value}\n`
    })
    prompt=prompt+`\n\nNow write a draft ${documentType} with the given information. Always try to fillup the document with necessary information. Don't give me any tips. Just give me the RAW document.`
    console.log(prompt)

    const completion = await client.chat.completions.create({
        model: modelName,
        messages: [
          {"role": "system", "content": `You are a lawyer who specializes in ${lawType}. You often write a lot of documents for your clients and do a lot of tasks for them.`},
          {"role": "user", "content": `${prompt}`}
        ]
      });
    const tokenCount=completion.usage.total_tokens
    console.log(completion.choices[0].message.content)
    const cost=tokenCount*15/1000000
    console.log("COST : ",cost*130)
    return ({
        "content":completion.choices[0].message.content,
        "tokens":tokenCount,
        "cost":cost*130
    })
}