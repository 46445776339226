import React,{useState} from 'react'
import MarkdownPreview from '@uiw/react-markdown-preview';
import { FaAngleDown,FaAngleUp } from 'react-icons/fa6';
export default function PDFCardMarkdown({data,idx}) {
    const [showDetail,setShowDetail]=useState(false)
  return (
    <div className='w-full px-4 '>
        <div className='w-full my-2 bg-white p-4 md:p-6 flex items-center justify-between'>
            <div className='flex items-center'>
                <a target='_blank' className='p-2 rounded border hover:bg-gray-200' href={data.pdf_link}>📒</a>
                <div className='flex flex-col ml-2'>
                    <p className='text-sm font-semibold'>Case File {idx+1}</p>
                    <p className='text-xs text-gray-700'>Contains the full case in this PDF</p>
                    <p className='text-xs text-gray-700'>[Click on the 📒 icon to download]</p>
                </div>
            </div>
            <div>
               {!showDetail && <button className='hover:bg-gray-100 ml-2 p-1 md:p-2 rounded-full border' onClick={()=>setShowDetail(true)}><FaAngleDown/></button>}
               {showDetail && <button className='hover:bg-gray-100 ml-2 p-1 md:p-2 rounded-full border' onClick={()=>setShowDetail(false)}><FaAngleUp/></button>}
            </div>
        </div>
        {showDetail && <div>
            <MarkdownPreview source={data.summary} className='text-xs' style={{ padding: 20,fontSize:"12px",fontFamily:"Poppins" }} wrapperElement={{"data-color-mode": "light"}} />
        </div>}
    </div>
  )
}
