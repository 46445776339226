import React from 'react'
import { CgDarkMode, CgProfile } from 'react-icons/cg'

export default function Navbar() {
  return (
    <div className='fixed bg-white top-0 right-0 bg-white pl-12 md:pl-8 p-4 h-16 w-full flex items-center justify-between md:justify-end'>
        <div className='flex md:hidden items-center'>
          <img src="https://i.postimg.cc/x1CsY9DH/1x1-Images-28.png" className='w-8 h-8 mr-2'/>
          <div className=''>
              <h2 className='font-semibold text-sm md:text-md'>superattorney.ai</h2>
              <p className='text-xs'>Support agent for our super attorneys</p>
          </div>
        </div>
        <div className='space-x-2 '>
          {/* <button className='p-2 bg-gray-100 border border-gray-100 rounded-lg shadow-lg hover:border-indigo-500 text-xl'><CgDarkMode/></button> */}
          <button className='p-1 md:p-2  border border-gray-200  shadow-lg hover:border-indigo-500 text-xl'><CgProfile /></button>
        </div>
    </div>
  )
}
