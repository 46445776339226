import React, { useState } from "react";
import openai from "../openaiClient"; // Make sure this is correctly set up with your OpenAI key
import ReactMarkdown from 'react-markdown';

export default function CaseChatter({ caseJson }) {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Function to handle sending messages to OpenAI
  const handleSendMessage = async () => {
    if (input.trim() === "") return;

    const userMessage = {
      role: "user",
      content: input,
    };

    const updatedMessages = [...messages, userMessage];

    setMessages(updatedMessages);
    setInput("");
    setIsLoading(true);

    try {
      // System message containing the case context
      const systemMessage = {
        role: "system",
        content: `You are a highly skilled lawyer. Answer questions regarding the following case with a focus on legal reasoning and analysis:\n\n${JSON.stringify(
          caseJson,
          null,
          2
        )}`,
      };

      const response = await openai.chat.completions.create({
        model: "gpt-4o-mini",
        messages: [systemMessage, ...updatedMessages],
      });

      const assistantMessage = {
        role: "assistant",
        content: response.choices[0].message.content,
      };

      setMessages([...updatedMessages, assistantMessage]);
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-full mx-auto p-6 bg-white rounded-lg shadow-lg">
      <span className="block text-lg font-semibold text-gray-800 mb-4">
        ⚖️ Chat with case
      </span>
      <div className="flex flex-col space-y-3 overflow-auto max-h-72">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`flex ${
              message.role === "user" ? "justify-end" : "justify-start"
            }`}
          >
            <div
              className={`${
                message.role === "user"
                  ? "bg-blue-100 text-blue-800"
                  : "bg-green-100 text-green-800"
              } text-sm px-4 py-2 rounded-lg shadow`}
            >
              <strong>{message.role === "user" ? "You" : "Lawyer"}:</strong>{" "}
              <ReactMarkdown>{message.content}</ReactMarkdown>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-6">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Ask a question about the case..."
          className="border text-sm border-gray-300 focus:ring focus:ring-blue-200 focus:outline-none rounded-lg p-3 w-full transition duration-300 ease-in-out"
          disabled={isLoading}
        />
        <button
          onClick={handleSendMessage}
          className={`mt-3 w-full px-4 py-2 font-semibold text-white shadow ${
            isLoading
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-purple-900 hover:bg-purple-800"
          } transition duration-300 ease-in-out`}
          disabled={isLoading}
        >
          {isLoading ? "Thinking..." : "Send"}
        </button>
      </div>
    </div>
  );
}
