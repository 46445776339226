import React,{useState} from 'react'
import { IoDocumentTextOutline } from "react-icons/io5";
import { LiaBriefcaseSolid } from "react-icons/lia";
import { PiExamLight } from "react-icons/pi";
import { TbDeviceAnalytics } from "react-icons/tb";
import { MdSupportAgent  } from "react-icons/md";
import { CgClose, CgProfile } from "react-icons/cg";
import {motion} from 'framer-motion'
import { MdOutlineFactCheck  } from 'react-icons/md';
import { CgLoadbarDoc } from "react-icons/cg";
import { GiHamburgerMenu } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';
export default function SidebarLarge() {
    const navigate=useNavigate()
    const [sidebarActive,setSidebarActive]=useState(false)
    if(!sidebarActive && window.screen.width<600){
        //console.log("HELP")
        return(
          <aside id="default-sidebar" class="fixed top-2 left-2 z-10" aria-label="Sidenav">
            <button onClick={()=>setSidebarActive(true)} variant="outline" className="fixed top-4 left-0 p-2 border shadow-md bg-white border-gray-200">
              <GiHamburgerMenu width={16} height={16}/>
            </button>
          </aside>
      )
      }
  return (
    <div className="z-10 fixed flex flex-col justify-between top-0 left-0 w-60 h-screen bg-white p-4 pt-12 shadow-lg">
        <button onClick={()=>setSidebarActive(false)} variant="outline" className="absolute md:hidden top-4 right-0 p-2 border shadow-md bg-white border-gray-200">
            <CgClose width={16} height={16}/>
        </button>
        <div className='relative'>
            
            <div className='mb-4'>
                <img src="https://i.postimg.cc/x1CsY9DH/1x1-Images-28.png" className='w-12 h-12'/>
                <h2 className='font-semibold text-xl md:text-2xl'>superattorney.ai</h2>
                <p className='text-xs md:text-sm'>Support agent for our super attorneys</p>
            </div>
            <div className='py-8 w-full'>
                {/* <motion.button whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='flex items-center w-full my-2 border border-gray-100 rounded shadow-md p-4 text-xs md:text-md font-semibold hover:bg-gray-100 hover:border-primary-500 text-left'><TbDeviceAnalytics className='mr-4' size={32}/>Analyze Document</motion.button>  */}
                <motion.button onClick={()=>navigate("/draft")} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='flex items-center w-full my-2 border border-gray-100 rounded shadow-md p-4 text-xs md:text-md font-semibold hover:bg-gray-100 hover:border-primary-500 text-left'><IoDocumentTextOutline className='mr-4' size={32}/>Create Legal Document</motion.button> 
                <motion.button onClick={()=>navigate("/")} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='flex items-center w-full my-2 border border-gray-100 rounded shadow-md p-4 text-xs md:text-md font-semibold hover:bg-gray-100 hover:border-primary-500 text-left'><LiaBriefcaseSolid className='mr-4' size={32}/>Find Relevant Cases</motion.button> 
                <motion.button onClick={()=>navigate("/acts")} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='flex items-center w-full my-2 border border-gray-100 rounded shadow-md p-4 text-xs md:text-md font-semibold hover:bg-gray-100 hover:border-primary-500 text-left'><MdOutlineFactCheck  className='mr-4' size={32}/>Act Finder</motion.button> 
                <motion.button onClick={()=>navigate("/chatter")} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='flex items-center w-full my-2 border border-gray-100 rounded shadow-md p-4 text-xs md:text-md font-semibold hover:bg-gray-100 hover:border-primary-500 text-left'><MdSupportAgent   className='mr-4' size={32}/>Agent Chatter</motion.button> 
                <motion.button onClick={()=>navigate("/case-organization")} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='flex items-center w-full my-2 border border-gray-100 rounded shadow-md p-4 text-xs md:text-md font-semibold hover:bg-gray-100 hover:border-primary-500 text-left'><CgLoadbarDoc   className='mr-4' size={32}/>Case Organization</motion.button> 
                
                {/* <motion.button whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='flex items-center w-full my-2 border border-gray-100 rounded shadow-md p-4 text-xs md:text-md font-semibold hover:bg-gray-100 hover:border-primary-500 text-left'><MdOutlineRemoveRedEye className='mr-4' size={32}/>Super Proofreader</motion.button> 
                <motion.button whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='flex items-center w-full my-2 border border-gray-100 rounded shadow-md p-4 text-xs md:text-md font-semibold hover:bg-gray-100 hover:border-primary-500 text-left'><PiExamLight className='mr-4' size={32}/>Self-test</motion.button>  */}
            </div>
        </div>
        <div>
            <motion.button whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='flex items-center w-full my-2 border border-gray-100 rounded shadow-md p-4 text-xs md:text-md font-semibold hover:bg-gray-100 hover:border-primary-500'><CgProfile className='mr-4' size={32}/>My Profile</motion.button> 
        </div>
    </div>
  )
}
